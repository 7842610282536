<template>
  <div class="col-sm-12 col-md-8 col-lg-4 mx-auto form-create-service">
    <OutletSelect @change="onSelectOutlet"></OutletSelect>
    <KTPortlet title="Chuyển tiếp khách hàng" headSize="md">
      <template v-slot:body>
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            id="forward-client-group"
            label="Khách chuyển tới"
            label-for="forwardClient"
          >
            <b-form-select
              id="forwardClient"
              :options="forwardClient"
              v-model="selectedForwardClient"
              @change="onSelectForwardClient"
              text-field="customer.code"
              value-field="_id"
              required
            ></b-form-select>
          </b-form-group>
        </b-form>
        <b-button
          v-on:click="onSubmit()"
          class="change-btn-right"
          variant="primary"
          >Bắt đầu</b-button
        >
      </template>
    </KTPortlet>
  </div>
</template>

<style lang="scss">
@import "./ForwardClient.scss";
</style>
<script>
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import { JSONParser } from "@amcharts/amcharts4/core";
import { getCurrentDate } from "../../common/utils/getDate";

export default {
  name: "List",
  components: {
    KTPortlet,
    OutletSelect
  },
  data() {
    return {
      selectedForwardClient: "",
      forwardClient: [
        {
          _id: "",
          customer: {
            code: "Khách mới"
          }
        }
      ],
      form: {
        newClient: ""
      },
      newClient: "Khách mới",
      query: {},
      codeSession: ""
    };
  },
  created() {
    const hasActivity = localStorage.getItem("idActivity");
    const hasInfoProduct = localStorage.getItem("infoProduct");
    if (hasActivity || hasInfoProduct) {
      this.$router.push({
        name: "forward-client-service"
      });
    }
  },
  methods: {
    onSubmit() {
      if (!this.selectedForwardClient) {
        this.$store.dispatch("createSession", this.query).then(data => {
          this.$router.push({
            name: "forward-client-service"
          });
          const dataSession = {
            idSession: data.data._id,
            codeSession: data.data.customer.code
          };
          localStorage.setItem("session", JSON.stringify(dataSession));
        });
      } else {
        this.$router.push({
          name: "forward-client-service",
          params: {
            idSession: this.selectedForwardClient,
            outletId: this.outletIdCurrent,
            codeSession: this.codeSession
          }
        });
        const dataSession = {
          idSession: this.selectedForwardClient,
          outletId: this.outletIdCurrent,
          codeSession: this.codeSession
        };
        localStorage.setItem("session", JSON.stringify(dataSession));
      }
    },
    onSelectForwardClient(event) {
      const dataTemp = this.forwardClient.filter(item => item._id === event);
      if (dataTemp.length !== 0) {
        this.codeSession = dataTemp[0].customer.code;
      }
    },

    getListSession() {
      this.query.outletId = this.outletIdCurrent;
      const queryGet = { status: 0, from: getCurrentDate() };
      this.$store
        .dispatch("getListSession", { ...queryGet, ...this.query })
        .then(() => {
          const listSession = this.$store.state.sessions.listSession;
          this.forwardClient = [...this.forwardClient, ...listSession];
          this.selectedForwardClient = this.forwardClient[0]._id;
        });
    },

    onSelectOutlet(event) {
      this.outletIdCurrent = event;
      this.getListSession();
    }
  }
};
</script>
